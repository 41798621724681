@import url('https://api.fontshare.com/v2/css?f[]=satoshi@1&display=swap');

$main: #de1149;
$sec: #38383d;
$bg: #f9f9f9;

$font: 'Satoshi', sans-serif;
$fontAlt: 'Nasalization', Helvetica, sans-serif;

// Spacing
$space_0_5: 15px;
$space_1: 30px;
$space_1_5: 45px;
$space_2: 60px;

.fontBasics {
  font-family: $font;
  font-style: normal;
  line-height: 40px;
  font-feature-settings: 'liga' off;
  color: $sec;
}

.transition {
  transition: all 300ms ease-in-out;
  &:hover {
    transition: all 300ms ease-in-out;
  }
}
.fadeInAnim {
  animation: fadeIn 600ms ease-in-out 0ms 1 normal forwards;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.error {
  width: 100% !important;
  background: #eee;
  border-radius: 8px;
  padding: 5px 0px;
  color: $sec !important;
  font-family: $font;
  font-weight: 900;
}

// Box shadows, and linear gradients

$input-shadow: 1px 1px 2px rgba(255, 255, 255, 0.3),
  -1px -1px 2px rgba(202, 202, 202, 0.5),
  inset -5px 5px 10px rgba(202, 202, 202, 0.2),
  inset 5px -5px 10px rgba(202, 202, 202, 0.2),
  inset -5px -5px 10px rgba(255, 255, 255, 0.9),
  inset 5px 5px 13px rgba(202, 202, 202, 0.9);

$light-background: linear-gradient(135deg, #e9e9e9 0%, #ffffff 100%);
$light-shadow: -5px 5px 10px rgba(220, 220, 220, 0.2),
  5px -5px 10px rgba(220, 220, 220, 0.2),
  -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(220, 220, 220, 0.9),
  inset 1px 1px 2px rgba(255, 255, 255, 0.3),
  inset -1px -1px 2px rgba(220, 220, 220, 0.5);
