@import 'variables.scss';
.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: $space_0_5 $space_1;
  & > h3 {
    color: $main;
    font-family: $fontAlt;
    font-size: 30px;
    margin: 0;
    font-weight: 200;
  }
  & > div {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    gap: $space_1;
    button {
      color: $main;
    }
  }
}
.drawer {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 9999;
  background-color: $bg;
  box-shadow: 5px 5px 10px rgba(224, 224, 224, 0.2),
    -5px -5px 10px rgba(224, 224, 224, 0.2),
    5px -5px 10px rgba(255, 255, 255, 0.9),
    -5px 5px 13px rgba(224, 224, 224, 0.9),
    inset -1px 1px 2px rgba(255, 255, 255, 0.3),
    inset 1px -1px 2px rgba(224, 224, 224, 0.5);
  padding: $space_1;
  box-sizing: border-box;
  overflow: hidden;
  h3 {
    @extend .fontBasics;
    font-weight: 900;
    font-size: 35px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    svg {
      margin-left: $space_1;
    }
  }
  & > section {
    width: 100%;
    input {
      width: calc(100% - 80px);
      max-width: 100%;
    }
    svg {
      right: $space_0_5;
    }
  }
  &Background {
    background: rgba(56, 56, 56, 0.5);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
  }
}
