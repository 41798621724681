@import 'variables.scss';
.login {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: $space_1;
  height: calc(100% - 60px);
  &__button {
    margin-right: auto;
    margin-bottom: $space_0_5;
    background-color: transparent;
    border: none;
    padding: 0;
    font-family: $font;
    font-size: 15px;
    @extend .transition;
    &:hover {
      cursor: pointer;
      color: $main;
    }
  }
  &__signUp {
    color: $main;
  }
  p {
    @extend .fontBasics;
  }
  & > section {
    width: 100%;
    input {
      width: calc(100% - 80px);
      max-width: 100%;
    }
    svg {
      right: $space_0_5;
    }
  }
  & > button:nth-last-child(1) {
    margin-top: $space_1;
  }
  &__stayTuned {
    border-radius: 12px;
    background-color: $bg;
    width: calc(100% - 60px) !important;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 101;
    padding: 30px;
    overflow: hidden;
    p {
      text-align: center;
      line-height: 1.5;
    }
    &Background {
      background-color: rgba($sec, 0.3);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 99;
      border-radius: 12px;
    }
  }
}

.signUp {
  position: relative;
  padding: $space_1;
  top: -80px;
  h3 {
    font-family: $font;
    font-size: 30px;
    text-align: center;
    font-weight: 900;
    margin-top: 0;
  }
  & > button:nth-last-child(1) {
    background-color: transparent;
    border: none;
    padding: 0;
    color: $main;
    cursor: pointer;
    margin-top: $space_1;
    font-family: $font;
    font-size: 15px;
  }
  & > section {
    width: 100%;
    input {
      width: calc(100% - 80px);
      max-width: 100%;
    }
    svg {
      right: $space_0_5;
    }
  }
  p {
    text-align: center;
    @extend .fontBasics;
  }
}
.forgot {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: $space_1;
  &Container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: $space_2;
  }
  h3 {
    font-family: $font;
    font-size: 30px;
    text-align: center;
    font-weight: 900;
    margin-top: 0;
  }
  & > section {
    width: 100%;
    input {
      width: calc(100% - 80px);
      max-width: 100%;
    }
    svg {
      right: $space_0_5;
    }
  }
}
.successBackground {
  background-color: rgba($sec, 0.3);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  border-radius: 12px;
}
.success {
  width: calc(100% - ($space_1_5 * 2));
  position: absolute;
  overflow: hidden;
  left: 0;
  bottom: 0;
  background-color: $bg;
  z-index: 999;
  border-radius: 12px;
  font-family: $font;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 $space_1_5;
  & > * {
    @extend .fadeInAnim;
  }
  h3 {
    text-align: center;
    font-size: 24px;
    margin: 0;
  }
  p {
    text-align: center;
    margin: $space_0_5 0 $space_1 0;
    line-height: 1.5;
  }
}
.category {
  position: relative;
  button {
    position: relative;
    svg {
      position: relative;
    }
  }
}
.categorySignUp {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
