@import './variables.scss';
.partnerContainer {
  width: 100%;
  height: 75vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  padding-top: 0;
  & > h3 {
    width: 100%;
    margin: 0 0 $space_0_5 0;
    text-align: center;
    @extend .fontBasics;
    font-weight: 900;
    font-size: 35px;
  }
  & > p {
    margin: 0 0 $space_1 0;
    @extend .fontBasics;
    font-size: 18px;
  }
}
.partner {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(1, 1fr);
  justify-content: center;
  width: 70vw;
  gap: $space_1;
  div {
    cursor: pointer;
    position: relative;
    padding: 0;
    @extend .transition;
    h3 {
      @extend .fontBasics;
      font-size: 30px;
      font-weight: 700;
      margin: $space_1 0 0 $space_1;
    }
    svg {
      position: absolute;
      bottom: 0;
      right: 0;
    }
    &:hover {
      background-color: $main;
      h3 {
        color: $bg;
      }
    }
  }
}
.partnerLeft {
  z-index: 1;
  animation: partnerLeft 1s ease-in-out 0ms 1 normal forwards;
}
.partnerRight {
  z-index: 1;
  animation: partnerRight 1s ease-in-out 0ms 1 normal forwards;
}
.partnerActive {
  z-index: 9999;
  background-color: $main !important;
  h3 {
    color: $bg !important;
  }
}
.hideTitle {
  animation: hide 1.3s ease-in-out 0ms 1 normal forwards;
}
.showTitle {
  animation: show 1.3s ease-in-out 0ms 1 normal forwards;
}
.hideCard {
  animation: hide 1s ease-in-out 0ms 1 normal forwards;
}
.showLogin {
  cursor: default !important;
  &:hover {
    background-color: $bg !important;
    h3 {
      color: $sec !important;
    }
  }
}
@keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes partnerLeft {
  0% {
    right: 0;
  }
  100% {
    right: calc(-100% - 30px);
  }
}
@keyframes partnerRight {
  0% {
    left: 0;
  }
  100% {
    left: calc(-100% - 30px);
  }
}
