@import './variables.scss';
.footer {
  padding: $space_0_5 $space_1;

  background: #f9f9f9;
  box-shadow: -5px -5px 10px rgba(224, 224, 224, 0.2),
    5px 5px 10px rgba(224, 224, 224, 0.2),
    -5px 5px 10px rgba(255, 255, 255, 0.9),
    5px -5px 13px rgba(224, 224, 224, 0.9),
    inset 1px -1px 2px rgba(255, 255, 255, 0.3),
    inset -1px 1px 2px rgba(224, 224, 224, 0.5);
  position: fixed;
  bottom: 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: calc(100% - ($space_1 * 2));
  section {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: $space_1;
  }
  h3 {
    @extend .fontBasics;
    font-weight: 900;
    margin: 0;
    font-family: $fontAlt;
  }
  a {
    @extend .fontBasics;
    margin-bottom: -5px;
    padding: 0;
    text-decoration: none;
    font-size: 15px;
  }
}
