@import 'variables.scss';

.creators {
  display: grid;
  grid-template-columns: 66% 33%;
  grid-template-rows: 100px 1fr;
  grid-column-gap: $space_1;
  grid-row-gap: $space_1;
  margin: $space_1 $space_1_5;
  &__title {
    grid-area: 1 / 1 / 2 / 2;
    @extend .fontBasics;
    font-size: 40px;
    img {
      width: 40px;
      height: 40px;
      margin-bottom: -5px;
    }
  }
  &__details {
    margin: 0px 0 !important;
    width: calc(100% - $space_1_5) !important;
    // padding: $space_1;
    grid-area: 2 / 1 / 3 / 2;
    min-height: 320px;
    & .details {
      &__wallet,
      &__subtitle {
        @extend .fontBasics;
        font-size: 20px;
        margin-bottom: 0;
        font-weight: 700;
        display: flex;
        align-items: center;
        svg {
          margin-left: $space_0_5;
        }
      }
      &__info {
        position: relative;
        background: linear-gradient(
          105.9deg,
          #de1149 -3.01%,
          rgba(222, 17, 73, 0) 109.84%
        );
        border-radius: 12px;
        padding: $space_1;
        @extend .fontBasics;
        line-height: 1.6;
        color: $bg;
        svg {
          position: absolute;
          top: $space_0_5;
          right: $space_0_5;
        }
      }
      &__walletAddress {
        @extend .fontBasics;
        font-size: 14px;
        margin-bottom: 0;
        font-weight: 700;
        display: flex;
        align-items: center;
        padding: 5px $space_1;
        background: $bg;
        box-shadow: $input-shadow;
        border-radius: 12px;
        width: fit-content;
        svg {
          margin-left: $space_0_5;
        }
        &Wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          h3 {
            margin-right: $space_1;
            margin-top: 0;
          }
          button {
            margin: 0;
            margin-right: $space_1;
            transform: scale(1.3);
          }
        }
      }
      &__hypeLink {
        @extend .details__walletAddress;
        // padding: $space_0_5 $space_1;
        min-width: 265px;
        svg {
          cursor: pointer;
          margin-left: auto;
        }
        &Container {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        margin-right: $space_1;
        margin-top: 0;
      }
      &__actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: $space_1;
        margin: $space_1 0 10px 0;
        & > button {
          min-width: 250px;
        }
        & > button:nth-child(1) {
          color: $main;
          min-width: 300px;
        }
      }
      &__creators {
        h3 {
          @extend .fontBasics;
          font-size: 20px;
        }
        &Headings {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: $space_2;
          padding: 0 $space_1;
          h3 {
            @extend .fontBasics;
          }
          p {
            @extend .fontBasics;
            font-weight: 500;
            margin: 0;
          }
        }
        &List {
          & > div {
            overflow: visible;
            display: flex;
            flex-direction: row;
            align-items: center;
            background-color: $light-background;
            box-shadow: $light-shadow;
            border-radius: 12px;
            margin: 0 0 $space_1 0;
            padding: $space_1;
            p,
            h4,
            h3 {
              @extend .fontBasics;
              margin: 0 $space_1 0 0;
            }
            h4 {
              font-weight: 900;
              color: $main;
              font-size: 22px;
            }
          }
        }
      }
    }
  }
  &__earnings {
    margin: 0px 0 !important;
    width: calc(100% - $space_1_5) !important;
    grid-area: 1 / 2 / 3 / 3;
    & > button {
      margin-bottom: 10px;
    }
    h2 {
      @extend .fontBasics;
      font-size: 30px;
    }
    & .earnings {
      &__container {
        h3 {
          @extend .fontBasics;
          text-align: left;
          margin: 0;
          font-size: 35px;
          font-weight: 900;
        }
        p {
          @extend .fontBasics;
          text-align: left;
          font-size: 20px;
          margin: 0;
        }
      }
      &__stats {
        display: flex;
        flex-direction: row;
        gap: $space_1;
        margin: calc($space_2 + 5px) 0;
        div {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: $space_0_5;
          h3 {
            @extend .fontBasics;
            font-size: 20px;
            margin: 0;
            font-weight: 500;
          }
        }
        h3 {
          @extend .fontBasics;
          font-size: 35px;
          margin: 0;
          font-weight: 700;
        }
      }
    }
  }
}
.creators {
  &__campaigns {
    font-family: $font;
    &Wallet {
      display: inline-flex;
      align-items: center;
      margin-bottom: $space_1;
      div {
        display: flex;
        align-items: center;
        flex-direction: row;
        h3 {
          padding: 5px $space_1;
          border-radius: 8px;
          background: $bg;
          box-shadow: $input-shadow;
          margin-left: $space_1;
        }
        button {
          transform: scale(1.3);
          margin-left: $space_1;
        }
      }
    }
  }
}
.campaigns {
  &__item {
    display: flex;
    width: 70%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: $bg;
    box-shadow: $light-shadow;
    padding: $space_0_5 $space_1;
    border-radius: 12px;
    margin: 0 0 $space_1 0;
    div:nth-last-child(1) {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      h3,
      p {
        margin: 10px 0;
        text-align: left;
      }
      h3 {
        color: $main;
      }
    }
  }
}

.earnings__button {
  margin-top: 200px;
}
