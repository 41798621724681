@import 'variables.scss';
.claim {
  width: 100%;
  height: 65vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > div {
    h2 {
      @extend .fontBasics;
      font-weight: 900;
      text-align: center;
      margin: 0 0 $space_0_5 0;
    }
    position: relative;
    padding: $space_1 $space_1;
    & > section {
      width: 100%;
      input {
        width: calc(100% - ($space_1 * 2));
        max-width: 100%;
        padding: 0px $space_1;
        text-align: center;
      }
      svg {
        right: $space_0_5;
      }
    }
  }
  p {
    width: 20%;
    @extend .fontBasics;
    line-height: 1.5;
    text-align: center;
    margin: $space_1 0 0 0;
  }
}

.confirmBackground {
  background: rgba(56, 56, 56, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 12px;
}
.confirm {
  position: absolute;
  bottom: 0;
  background-color: $bg;
  left: 0;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: $space_0_5 0;
  overflow: hidden;
  p {
    text-align: center;
    @extend .fontBasics;
    font-weight: 700;
    width: 50%;
    line-height: 1.3;
    margin: $space_0_5 0;
  }
  p:nth-child(2) {
    margin: 0 0 $space_1 0;
    overflow: scroll hidden;
    width: 70%;
    &::-webkit-scrollbar {
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #eee;
    }
    &::-webkit-scrollbar-thumb {
      background: $main;
      border-radius: 3px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: $main;
    }
  }
  & > section {
    display: flex;
    flex-direction: row;
    gap: $space_1;
    button {
      min-width: 100px;
    }
    margin-bottom: 10px;
  }
}
