@font-face {
  font-family: 'Nasalization';
  src: local('Nasalization'),
    url('../theme/fonts/nasalization-rg.ttf') format('TrueType');
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy'), url('../theme/fonts/gilroyLight.otf') format('TrueType');
}

@font-face {
  font-family: 'Satoshi';
  src: local('Satoshi'), url('../theme/fonts/Satoshi-Regular.ttf') format('TrueType');
}

body {
  margin: 0;
}
